import React from "react"
import Text from "@src/components/Text"
import * as styles from "./FeatureBig.module.scss"
const FeatureBig = ({
  icon: Icon,
  title,
  description,
  tag,
  link,
  vertical,
  smallerGrid,
  white,
  withBand,
  fullWidth,
  className = "",
  small,
  card,
}) => {
  const verticalClassName = vertical ? styles.vertical : ""
  const smallerGridClassName = smallerGrid ? styles.smallerGrid : ""
  const whiteClassName = white ? styles.white : ""
  const fullWidthClassName = fullWidth ? styles.fullWidth : ""
  const smallClassName = small ? styles.small : ""
  const withBandClassName = withBand ? styles.withBand : ""
  const cardClassName = card ? styles.card : ""
  const classNames = [
    verticalClassName,
    smallerGridClassName,
    whiteClassName,
    withBandClassName,
    fullWidthClassName,
    smallClassName,
    cardClassName,
  ].join(" ")

  return (
    <li className={`${styles.feature} ${classNames} ${className}`}>
      {Icon && (
        <figure className={styles.featureIcon}>
          {typeof Icon === "string" ? <img alt={title} src={Icon} /> : <Icon />}
        </figure>
      )}
      <div className={`${styles.featureText}`}>
        <Text.H3 className={styles.featureTitle}>
          {title}
          {tag && <span className={styles.tag}>{tag}</span>}
        </Text.H3>
        <Text.P
          dangerouslySetInnerHTML={{ __html: description }}
          className={styles.featureDescription}
        />

        {link && (
          <a href={link.url} target="_blank" className={styles.link}>
            {link.title}
          </a>
        )}
      </div>
    </li>
  )
}

export default FeatureBig
