// extracted by mini-css-extract-plugin
export var arrow = "FeatureBig-module--arrow--2mTbd";
export var card = "FeatureBig-module--card--2VO5N";
export var feature = "FeatureBig-module--feature--3Qb1c";
export var featureIcon = "FeatureBig-module--featureIcon--3QD8K";
export var fullWidth = "FeatureBig-module--fullWidth--e3dPG";
export var link = "FeatureBig-module--link--28j1n";
export var rightArrowIcon = "FeatureBig-module--rightArrowIcon--1_pyS";
export var showIn = "FeatureBig-module--show-in--3a5KM";
export var small = "FeatureBig-module--small--PAQAo";
export var smallerGrid = "FeatureBig-module--smallerGrid--1O_KT";
export var tag = "FeatureBig-module--tag--2VEgm";
export var vertical = "FeatureBig-module--vertical--2ktPw";
export var white = "FeatureBig-module--white--1DwIn";
export var withBand = "FeatureBig-module--withBand--7PYVo";