// extracted by mini-css-extract-plugin
export var active = "FeatureGrid-module--active--2_3ty";
export var black = "FeatureGrid-module--black--1grbu";
export var blue = "FeatureGrid-module--blue--31van";
export var featureGrid = "FeatureGrid-module--featureGrid--3UI9G";
export var featureGridItem = "FeatureGrid-module--featureGridItem--2u9or";
export var featureGridWrapper = "FeatureGrid-module--featureGridWrapper--1WoaU";
export var noMargin = "FeatureGrid-module--noMargin--1mHWM";
export var showIn = "FeatureGrid-module--show-in--3fex0";
export var vertical = "FeatureGrid-module--vertical--2Y5W5";
export var white = "FeatureGrid-module--white--KoPUt";
export var withBand = "FeatureGrid-module--withBand--3_BYb";