import React from "react"

import activateOnScreen from "@src/hoc/activateOnScreen"
import FeatureBig from "@src/components/FeatureBig"

import * as styles from "./FeatureGrid.module.scss"

const FeatureGrid = activateOnScreen(
  ({
    children,
    blue,
    active,
    white,
    vertical,
    innerRef,
    noMargin,
    withBand,
    black,
    fullWidth,
    renderItem,
    smallerGrid,
    className,
    small,
    cards,
  }) => {
    const activeClassName = active ? styles.active : ""
    const blueClassName = blue ? styles.blue : ""
    const blackClassName = black ? styles.black : ""
    const whiteClassName = white ? styles.white : ""
    const noMarginClassName = noMargin ? styles.noMargin : ""
    const smallClassName = small ? styles.small : ""
    const fullWidthClassName = fullWidth ? styles.fullWidth : ""
    const verticalClassName = vertical ? styles.vertical : ""
    const withBandClassName = withBand ? styles.withBand : ""
    const smallerGridClassName = smallerGrid ? styles.smallerGrid : ""
    return (
      <ul
        className={`${styles.featureGrid} ${withBandClassName} ${blueClassName} ${whiteClassName} ${blackClassName} ${verticalClassName} ${activeClassName} ${noMarginClassName} ${fullWidthClassName} ${smallerGridClassName} ${className}`}
      >
        <div ref={innerRef} className={styles.featureGridWrapper}>
          {children.map(
            renderItem ||
              (({ title, description, tag, link, identifier, icon: Icon }) => (
                <FeatureBig
                  fullWidth={fullWidth}
                  key={title}
                  white={blue || black}
                  className={styles.featureGridItem}
                  title={title}
                  description={description}
                  tag={tag}
                  link={link}
                  identifier={identifier}
                  icon={Icon}
                  vertical={vertical}
                  withBand={withBand}
                  smallerGrid={smallerGrid}
                  small={small}
                  card={cards}
                />
              ))
          )}
        </div>
      </ul>
    )
  }
)

export default FeatureGrid
